"use strict";

let viewportHeight = window.innerHeight;
let isRunning = false;
let $stickyCart = $('.js-sticky-cart');
let $watchElement = $('footer');

export function initInScope() {
    if(!_config.iframe) {
        isRunning = true;
        lastScrollPosition = 0;
        if($stickyCart.length) {
            requestAnimationFrame(interval);
        }
    }
}

let lastScrollPosition = 0;
export function interval() {
    if (lastScrollPosition === window.pageYOffset) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.pageYOffset;
    if ($watchElement[0].getBoundingClientRect().bottom <= viewportHeight+100) {
        $stickyCart.addClass('sticky-cart--hidden');
    } else {
        $stickyCart.removeClass('sticky-cart--hidden');
    }
    requestAnimationFrame(interval);
}