
// elements-cookie-service <-> webcare
let consentMapping = {
    //preferences: [],
    statistic: [ 'google', 'hyll'],
    marketing: [ 'google-adwords', 'facebook' ],
    //unknown: []
};

export function init() {
    // process queue on change
    if( typeof dr_cookiebanner_options !== 'undefined') {
        dr_cookiebanner_options.dr_onEnableCookies = function( enable ) {
            processCurrentQueue();
        }
        // process queue on-load with short timeout
        setTimeout( processCurrentQueue, 1000 );
    }
}


function isServiceConsented( consentStatus, serviceName ) {
    for( const consentName in consentMapping ) {
        if( consentStatus.hasOwnProperty( consentName ) && consentStatus[ consentName ] ) {
            if( consentMapping[ consentName ].includes( serviceName ) ) {
                return true;
            }
        }
    }
    return false;
}

function isAnyThingConsented( consentStatus ) {
    let contained = false;
    for( const consentName in consentMapping ) {
        if( consentStatus.hasOwnProperty( consentName ) && consentStatus[ consentName ] ) {
            contained = true;
            break;
        }
    }
    return contained;
}

function processQueue( consentStatus, callbacks ) {
    let remaining = [];
    if( callbacks && callbacks.length ) {
        callbacks.forEach( function( obj ) {
            if( isServiceConsented( consentStatus, obj.service ) ) {
                obj.callback();
            } else {
                remaining.push( obj );
            }
        } );
    }
    return remaining;
}

function processCurrentQueue() {
    const consentStatus = window.cookieconsent.currentConsentStatus();
    if( isAnyThingConsented( consentStatus ) ) {
        if( _config.registerPermissionGrantedCallback ) {
            _config.registerPermissionGrantedCallback = processQueue( consentStatus, _config.registerPermissionGrantedCallback );
        }
    }
}