"use strict";

export function initInScope ($scope) {
    let $dialogs = $scope.find('.js-confirm-dialog');

    $scope.find('.js-confirm-dialog__btn').on('click', function (evt) {
        evt.preventDefault();

        let dialogSelector = $(this).attr('data-target');
        if( dialogSelector ) {
            let $dialog = $(dialogSelector);

            if( $dialog.length ) {
                let confirmUrl = $(this).attr('data-href');
                let confirmFormId = $(this).attr('data-form');
                if( confirmUrl ) {
                    let $confirmBtn = $dialog.find('a.js-confirm-dialog__confirm');
                    $confirmBtn.attr('hidden',false)
                    $confirmBtn.attr('href',confirmUrl)
                } else
                if( confirmFormId ) {
                    let $confirmBtn = $dialog.find('button.js-confirm-dialog__confirm');
                    $confirmBtn.attr('hidden',false)
                    $confirmBtn.attr('form',confirmFormId)

                    $confirmBtn.on('click', function (evt) {
                        $dialog.attr('hidden',true);
                    })
                }

                $dialog.attr('hidden',false);
            }
        }

    });

    $scope.find('.js-confirm-dialog__cancel').on('click', function (evt) {
        evt.preventDefault();
        $(this).closest('.js-confirm-dialog').attr('hidden','hidden');
    });
}