"use strict";

export function initInScope($scope) {
    let dataAttributePrefix = 'event-tracking';
    let dataAttributeSelector = "[data-" + dataAttributePrefix + "-category]";

    let trim = function(str) {
        return str.replace(/\s\s+/g, ' ').trim();
    };

    $(document.body).on('click', dataAttributeSelector, function( evt ) {
        let clickedElement = this;
        let $clickedElement = $(clickedElement);
        let data = {
            category: null,
            action: null,
            label: null,
            value: null
        };

        if (clickedElement.tagName === 'A') {
            data.action = clickedElement.getAttribute('href');
            data.label = trim(clickedElement.textContent);
        }

        // simulate getPrefixedDataSet with whitelist
        let dataAttributes = {};
        let supportedNames = [
            'category', 'action', 'label', 'value'
        ];
        supportedNames.forEach( function( suffix ) {
            let name = dataAttributePrefix + '-' + suffix;
            if( $clickedElement.data( name ) ) {
                dataAttributes[ suffix ] = $clickedElement.data( name );
            }
        } )

        let eventData = $.extend( {}, data, dataAttributes );
        gtmEventTracker.trackEvent( eventData.category, eventData.action, eventData.label, eventData.value, false )
    })
}
export const gtmEventTracker = {

    setup: function() {
        window.dataLayer = window['dataLayer'] || [];
    },


    trackEvent: function(category, action, label, value, ignoreRentContext ) {
        let exists = function(x) {
            return x !== null && typeof x !== 'undefined'
        };

        let dataLayerData = {
            'event': 'genericGtmEvent',
            'genericEventCategory': category
        };
        if( exists(action) ) {
            dataLayerData[ 'genericEventAction'] = action;
        }
        if( exists(label) ) {
            dataLayerData[ 'genericEventLabel'] = label;
        }
        if( exists(value) ) {
            dataLayerData[ 'genericEventValue'] = value;
        }

        trackToGTM( dataLayerData, ignoreRentContext );
    }
};

export function trackToGTM( data, ignoreRentContext ) {

    if( typeof data !== 'object' ) {
        return;
    }

    if( typeof window.dataLayer === 'undefined' ) {
        gtmEventTracker.setup();
    }

    let trackingContext = null;
    if( typeof gtagRentContext === 'function' ) {
        // noinspection JSVoidFunctionReturnValueUsed
        trackingContext = gtagRentContext();
        if( typeof trackingContext === 'object') {
            // always enhance with rent-specific context (tenant,site,...) ?
            $.extend( data, trackingContext );
        }
    }


    if( typeof gtag === 'function' ) {
        // use provided function - declared in codeHead.html.twig
        gtagTrack( data );
    } else {
        if( app.debugGTM ) {
            console.log('GTM Tracking - dataLayer push:', data);
        }
        window.dataLayer.push(data);
    }
}

