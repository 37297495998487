"use strict";

export function initInScope($scope) {
    $scope.find('.js-social-login-button').on('click', function(e){
        e.preventDefault();
        var $this = $(this);
        if($this.data('mode') === 'server') {
            location.href = $this.data('url');
        } else {
            window.open($this.data('url'), "Login", "width=700,height=500");
        }
    });

}