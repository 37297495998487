"use strict";

import Modal from 'bootstrap/js/dist/modal';

export function init() {
    let modalTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="modal"]'))
    let modalList = modalTriggerList.map(function (modalTriggerEl) {
        return new Modal(modalTriggerEl);
    });
}

export function getCurrentOpenedModal() {
    const modalElements = document.querySelectorAll( 'div.modal');
    let opened = null;
    if( modalElements.length ) {
        for( const me of modalElements ) {
            let modalOf = Modal.getInstance( me );
            if( modalOf !== null && modalOf._isShown ) {
                opened = modalOf;
                break;
            }
        }
    }
    return opened;
}