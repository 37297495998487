'use strict'
import fetch from '../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill';
import initModulesInScope from "../libs/@elements/init-modules-in-scope";
import {clearAll, showNotification} from "../libs/@elements/alert-notification"; // Edge Polyfill
import {redirectFromResult} from './redirectHandler';

export function initInScope($scope) {

    let $modalBtn =  $scope.find('.js-ajax-modal-btn'),
        $modalContent;

    const $loading = $('.js-ajax-modal__loading');

    let pendingRequest;

    $modalBtn.on('click', (e) => {
        let url = $(e.currentTarget).data('url');
        let $modal =  $($(e.currentTarget).attr('data-bs-target'));
        let $dialog = $modal.find('.modal-dialog');
        $modalContent = $modal.find('.js-ajax-modal-content');
        let $modalNotifications = $modal.find('.js-ajax-modal__notifications');

        loadAjaxModal(url, $loading, $modalContent, $modalNotifications, pendingRequest)

        let position = e.currentTarget.getBoundingClientRect().top;

        if(_config.iframe) {
            $dialog.css('top', position-180); //set modal position in iframe
        }
    });


}

export function loadAjaxModal(url, $loading, $modalContent, $modalNotifications, pendingRequest) {
    showLoading($loading, $modalContent);
    if(pendingRequest) {
        pendingRequest.abort();
        pendingRequest = null;
    }

    pendingRequest = fetch(url, {
        method: 'GET'
    });

    clearAll({
        $container: $modalNotifications
    });

    showNotification(pendingRequest, {$container: $modalNotifications});

    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if ($modalContent.length) {
            if(res.success){
                if(res.html){
                    const $response = $(res.html);
                    $modalContent.html($response);
                    initModulesInScope($modalContent);
                }
            } else {
                if(res.html){
                    const $response = $(res.html);
                    $modalContent.html($response);
                    initModulesInScope($modalContent);
                }
            }
        }
        redirectFromResult( res );
    }).finally((res) => {
        hideLoading($loading, $modalContent);
    })
}

export function showLoading($loading, $modalContent){
    $loading.removeAttr('hidden');
    $modalContent.attr('hidden', true);
}
export function  hideLoading($loading, $modalContent){
    $loading.attr('hidden', true);
    $modalContent.attr('hidden', false);
    setTimeout(function(){
    }, 250);
}
