"use strict";

import Modal from 'bootstrap/js/dist/modal';
import {loadAjaxModal} from "./ajaxModal";

export function init () {
    let showModalId = _config.showModalId;
    let showModalAjaxUrl = _config.showModalAjaxUrl;

    if(showModalId) {

        let modalElement = document.getElementById(showModalId)
        if( modalElement ) {
            let bsModal = new Modal(modalElement, {});
            bsModal.show();

            if(showModalAjaxUrl) {
                let $modal = $('#'+showModalId);
                let pendingRequest;
                let $loading = $modal.find('.js-ajax-modal__loading');
                let url = showModalAjaxUrl;
                let $modalContent = $modal.find('.js-ajax-modal-content');
                let $modalNotifications = $modal.find('.js-ajax-modal__notifications');
                loadAjaxModal(url, $loading, $modalContent, $modalNotifications, pendingRequest);
            }


        }

    }

}