
function introspectResultForEvents( result ) {
    // ajax-form - success
    let eventResults = [];
    if( result != null && typeof result === 'object') {
        if( result.hasOwnProperty('events') && result.events && Array.isArray( result.events )) {

            result.events.forEach( function( resultEvent ) {

                let nextName = '';
                let nextData = {};
                if( typeof resultEvent === 'string') {
                    nextName = resultEvent;
                } else
                if( typeof resultEvent === 'object' && resultEvent.hasOwnProperty( 'name') && typeof resultEvent.name === 'string') {
                    nextName = resultEvent.name;
                    if( resultEvent.hasOwnProperty( 'data') && typeof resultEvent.data === 'object' ) {
                        nextData = resultEvent.data;
                    }
                }


                if( nextName.length ) {
                    eventResults.push( { name: nextName, data: nextData } );
                }

            } );
        }
    }
    return eventResults;
}

function triggerResultEvents( eventResults ) {
    if( eventResults && eventResults.length ) {
        eventResults.forEach( function( resultEvent ) {
            const nextEvent = new CustomEvent( resultEvent.name, { detail: resultEvent.data } );
            document.dispatchEvent( nextEvent );
        } );
    }
}


export function eventsFromResult( result ) {
    let eventResults = introspectResultForEvents( result );
    if( eventResults && eventResults.length ) {
        triggerResultEvents( eventResults );
    }
}

