"use strict";

import {getCurrentOpenedModal} from "./modal";

export function initInScope($scope) {
    if( $scope.find( '.js-modal-close').length ) {
        const bsModal = getCurrentOpenedModal();
        if( bsModal ) {
            bsModal.hide();
        }
    }
}