"use strict";

function isOnChangeAjaxForm( $form ) {
    // check if form is bound to ajax-form and ajax-form handles on-change by itself
    let $ajaxForm = null;
    if ($form.hasClass('js-ajax-form__form') ) {
        $ajaxForm = $form.closest('.js-ajax-form');
    } else
    if ($form.hasClass('js-ajax-form__form--nested')) {
        $ajaxForm = $form.closest('.js-ajax-form--nested');
    }
    return $ajaxForm && $ajaxForm.data('ajax-form') && $form.data('ajax-form-submit-on-change');
}

export function initInScope($scope) {
    let $inputs = $scope.find('.js-submit-on-change');

    $inputs.on('change', function () {
        let $form = $(this).closest('form');
        // only trigger if not handled by ajax-form itself
        if( !isOnChangeAjaxForm( $form ) ) {
            $form.submit();
        }
    });
}