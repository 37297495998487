import onInitModulesInScope from "./onInitModulesInScope";

import * as goToLink from '../libs/@elements/go-to-link';
goToLink.init();

if ($('.js-toc__list').length) {
    toc.init();
    onInitModulesInScope(toc.initInScope);
}

let alertKeyOverride = (_config && _config['alertNotificationKey']) ? _config['alertNotificationKey'] : '__alertNotification';
function alertRenderOverride({type = 'info', title, content, closable = true, styleModifier = '', placement} = {}, options) {
    if (content || title) {
        options.$container.attr('hidden', null);
        let titleHtml = title ? '<div class="alert-heading alert__headline h4">' + title + '</div>' : '';
        let alertStyle = closable ? 'alert-dismissible fade show': '';
        let buttonHtml = closable ? `<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="${options.closeText}"</button>` : ''

        return options.$container.append(
            `<div class="alert alert-${type} ${alertStyle} ${styleModifier}" role="alert">
                ${titleHtml}
                ${content ? (`<div class="">${content}</div>` ) : ''}
                ${buttonHtml}                
            </div>`
        );
    }
}
import {init as alertInit} from '../libs/@elements/alert-notification';
alertInit({
    key: alertKeyOverride,
    renderFunction: alertRenderOverride
})

import {redirectFromResult,redirectClientTo} from './redirectHandler';
import {eventsFromResult} from './resultEventsHandler';

// xhr-redirect handling w/o concrete scope
$("body").on('fetched.ajax-form', function( evt, result ) {
    redirectFromResult( result );
    eventsFromResult( result );
});


import * as navToggle from './navToggle';
onInitModulesInScope(navToggle.initInScope);

import * as submitOnChange from './submitOnChange';
onInitModulesInScope(submitOnChange.initInScope);

import * as imgCopyright from './imgCopyright';
onInitModulesInScope(imgCopyright.initInScope);

import * as stickyCart from './stickyCart';
onInitModulesInScope(stickyCart.initInScope);


import * as slider from '../libs/@elements/slider';
let sliderOptions = {
    prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"></button>',
    nextArrow: '<button type="button" class="slick-next" aria-label="Next"></button>',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
};
onInitModulesInScope(slider.createInitInScope('.js-slider', sliderOptions));

import * as tooltip from './tooltip';
tooltip.init();

import * as collapse from './collapse';
collapse.init();

import * as modal from './modal';
modal.init();

import * as alert from './alert';
onInitModulesInScope(alert.init);

import * as tab from './tab';
onInitModulesInScope(tab.init);

import * as ajaxModal from './ajaxModal';
onInitModulesInScope(ajaxModal.initInScope);

import * as showModal from './showModal';
showModal.init();

import * as closeModal from './closeModal';
onInitModulesInScope(closeModal.initInScope);

import * as lazyImg from '../libs/@elements/lazy-img';
onInitModulesInScope(lazyImg.initInScope);

import * as cookieConsent from './cookieConsent.js';
cookieConsent.init();

import * as socialLogins from './socialLogins';
onInitModulesInScope(socialLogins.initInScope);

import * as confirmDialog from './confirmDialog';
onInitModulesInScope(confirmDialog.initInScope);

import {initInScope as initTrackingInScope} from './tracking';
onInitModulesInScope(initTrackingInScope);
