const redirectDefaultOptions = {
    location: null,
    timeout: 500
}

export function redirectClientTo( uri, timeout = 0 ) {
    if( typeof uri === 'string' && uri.length > 0 && uri !== '#') {
        let redirector = function() {
            $('.js-fullpage-loading').attr('hidden',false);
            window.location.href = uri;
        };
        if( Number.isInteger( timeout ) && timeout > 0 ) {
            setTimeout( redirector, timeout );
        } else {
            redirector();
        }
        return true;
    } else {
        return false;
    }
}

function introspectResultForRedirect( result ) {
    // ajax-form - success
    let redirectResult = {};
    if( result != null && typeof result === 'object') {
        if( result.hasOwnProperty('redirect') && result.redirect ) {
            if( typeof result.redirect === 'string' && result.redirect.length ) {
                redirectResult = { location: result.redirect };
            } else
            if( typeof result.redirect === 'object') {
                redirectResult = result.redirect;
            }
            redirectResult = { ...redirectDefaultOptions, ...redirectResult };
        }
    }
    return redirectResult;
}

export function redirectFromResult( result ) {
    let redirectResult = introspectResultForRedirect( result );
    if( redirectResult && Object.keys(redirectResult).length ) {
        return redirectClientTo( redirectResult.location, redirectResult.timeout );
    } else {
        return false;
    }
}