"use strict";

export function initInScope ($scope) {
    // toggle search overlay

    $scope.find('.js-nav-collapse').on('click', function (evt) {
        evt.preventDefault();
        $(this).toggleClass('is-active');
        $scope.find('.js-nav-collapse__nav').toggleClass('is-visible');
        $scope.find('.js-main-search').removeClass('is-visible');
    });


    // toggle nav levels
    $scope.find('.js-nav-level__item-toggle').on('click', function (evt) {
        evt.preventDefault();
        let $navTreeItem = $(this).closest('.js-nav-level__item').find('.js-nav-level:first');

        if ($navTreeItem.hasClass('is-open')) {
            $navTreeItem.removeClass('is-open');
            $(this).removeClass('is-open');
        } else {
            $navTreeItem.addClass('is-open');
            $(this).addClass('is-open');
        }
    });


    // toggle search overlay
    $scope.find('.js-main-search__toggle').on('click', function (evt) {
        evt.preventDefault();
        $scope.find('.js-main-search').toggleClass('is-visible');
        $scope.find('.js-nav-collapse__nav').removeClass('is-visible');
    });
}